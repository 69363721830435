var render, staticRenderFns
import script from "./Create.vue?vue&type=script&lang=js&"
export * from "./Create.vue?vue&type=script&lang=js&"
import style0 from "./Create.vue?vue&type=style&index=0&id=939b3d2a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "939b3d2a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\www_current\\newtrena.liczi.net\\modules\\blockwishlist\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('939b3d2a')) {
      api.createRecord('939b3d2a', component.options)
    } else {
      api.reload('939b3d2a', component.options)
    }
    
  }
}
component.options.__file = "_dev/front/js/components/Create/Create.vue"
export default component.exports